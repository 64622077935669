import github from "../assets/images/github.svg";

export default [
  {
    id: 3,
    title: "Github",
    icon: github,
    linkTo: true,
    path: "https://github.com/chiefpunk",
  },
];
